<template>
  <div>
    <div class="flex items-center justify-between">
      <p class="font-medium text-darkblue sm:text-2xl text-xl">{{$t('Storage Slots')}}</p>
      <button @click="openSlotModal()" class="add-button bg-blue">
        <span class="text-2xl">+</span>
      </button>
    </div>
    <shipblu-table
      multiple
      v-model="selected"
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      search
      :data="slots"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
      </div>
      <template slot="thead">
        <shipblu-th>{{$t('Slot Name')}}</shipblu-th>
        <shipblu-th>{{$t('Number of Products')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <shipblu-td :data="data[indextr].name">
            {{ data[indextr].name }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].products">
            {{ data[indextr].product_count }}
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-prompt
      class="shipment-modal"
      @close="closeModal"
      @cancel="closeModal"
      :active.sync="addSlotModal"
      :title="$t('Create Storage Slot')"
      :buttons-hidden="true" autocomplete="nofill">
        <div class="mt-2 relative">
          <label :class="slot.name ? 'visible' : 'hidden'" class="input-label" for="slot">{{ $t('Slot Name') + ' *' }}</label>
          <input id="slot" :class="slot.name ? 'input-customer-info-filled' : ''" class="w-full input-customer-info" v-validate="'required'" name="slot name" :placeholder="$t('Slot Name')" v-model="slot.name"/>
          <span class="text-danger text-xs" v-show="errors.has('slot name')">{{ errors.first('slot name') }}</span>
        </div>
        <div class="grid grid-cols-2 gap-4 mt-4">
          <button @click="closeModal()" class="btn disable-btn">{{ $t('Cancel') }}</button>
          <button @click="addSlot()" class="active-btn btn">{{ $t('Add') }}</button>
        </div>
    </shipblu-prompt>
  </div>
</template>

<script>
import ShipbluTable from '../../layouts/shipblu-components/ShipBluTable.vue'
import ShipbluTr from '../../layouts/shipblu-components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/shipblu-components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/shipblu-components/ShipBluTd.vue'
import ShipbluPrompt from '../../layouts/shipblu-components/ShipBluPrompt.vue'
import { sendRequest } from '../../axios/requestHelper'
import common from '../../assets/utils/common'
import i18nData from '../../i18n/i18nData'

export default {
  data () {
    return {
      addSlotModal: false,
      slot: {},
      maximumItems: 10,
      selected: [],
      slots: [],
      searchVal: '',
      searchInProgress: false,
      searchedValue: ' '
    }
  },
  watch: {
  },
  methods: {
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadBlackListSearch)
    },
    openSlotModal () {
      this.addSlotModal = true
    },
    closeModal () {
      this.addSlotModal = false
      this.slot = {}
    },
    loadData () {
      sendRequest(this, 'api/storage/slots/', 'get', null, true,
        (response) => {
          this.slots = response.data
        }
      )
    },
    addSlot () {
      sendRequest(this, 'api/storage/slots/', 'post', {name: this.slot.name}, true,
        () => {
          common.notify({
            title: i18nData[this.$i18n.locale]['Success'],
            text: i18nData[this.$i18n.locale]['Slot has been updated successfully!'],
            color: '#28C76F'
          })
          this.loadData()
          this.closeModal()
        }
      )
    }
  },
  created () {
    this.loadData()
  },
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluPrompt
  }
}
</script>
